import * as React from 'react';
import './Button.css';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}

export const Button: React.FC<ButtonProps> = ({
    children,
    className,
    ...props
}: ButtonProps) => {
    return (
        <button className={`button ${className}`} {...props}>{children}</button>
    );
}
